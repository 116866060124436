import React from 'react';

import Layout from '../../components/Layout';
import SiteRoll from '../../components/SiteRoll';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="all-site">
          <SiteRoll />
        </div>
      </Layout>
    );
  }
}
